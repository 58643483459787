<section
	class="w-100 project-tasks-list-section px-2 d-flex flex-column h-100 position-relative"
	*ngVar="tasksListUI$ | async as tasksListUI"
>
	<div class="tasks-filter-container mat-caption py-4 pb-5 d-flex">
		<span *ngVar="taskFilterBy$$ | async as taskFilterBy">
			{{ 'project-tasks-list.show' | translate }}:
			<a href="" [matMenuTriggerFor]="tasksFilterMenu" class="text-primary font-weight-bold">
				<ng-container [ngSwitch]="taskFilterBy">
					<ng-container *ngSwitchCase="TaskFilterBy.OPEN"
						>{{ 'project-tasks-list.open-tasks' | translate }} ({{ openTasksLength | async }})</ng-container
					>
					<ng-container *ngSwitchCase="TaskFilterBy.COMPLETED"
						>{{ 'project-tasks-list.completed-tasks' | translate }} ({{ completedTasksLength | async }})</ng-container
					>
				</ng-container>
			</a>
			<mat-menu #tasksFilterMenu="matMenu">
				<button mat-menu-item [class.text-primary]="taskFilterBy === TaskFilterBy.OPEN" (click)="taskFilterBy$$.next(TaskFilterBy.OPEN)">
					{{ 'project-tasks-list.open-tasks' | translate }} ({{ openTasksLength | async }})
				</button>
				<button
					mat-menu-item
					[class.text-primary]="taskFilterBy === TaskFilterBy.COMPLETED"
					(click)="taskFilterBy$$.next(TaskFilterBy.COMPLETED)"
				>
					{{ 'project-tasks-list.completed-tasks' | translate }} ({{ completedTasksLength | async }})
				</button>
			</mat-menu>
		</span>

		<span class="ml-auto text-right sort-by-menu" *ngVar="taskSortBy$$ | async as taskSortBy">
			{{ 'project-tasks-list.sort-by' | translate }}:
			<a href="" [matMenuTriggerFor]="tasksSortMenu" class="text-primary font-weight-bold">
				<ng-container [ngSwitch]="taskSortBy">
					<ng-container *ngSwitchCase="TaskSortBy.TITLE_A_Z">{{ 'project-tasks-list.title-a-z' | translate }}</ng-container>
					<ng-container *ngSwitchCase="TaskSortBy.TITLE_Z_A">{{ 'project-tasks-list.title-z-a' | translate }}</ng-container>
					<ng-container *ngSwitchCase="TaskSortBy.ASSIGNEE_A_Z">{{ 'project-tasks-list.assignee-a-z' | translate }}</ng-container>
					<ng-container *ngSwitchCase="TaskSortBy.ASSIGNEE_Z_A">{{ 'project-tasks-list.assignee-z-a' | translate }}</ng-container>
					<ng-container *ngSwitchCase="TaskSortBy.DUE_DATE_L_O">{{ 'project-tasks-list.due-date-l-o' | translate }}</ng-container>
					<ng-container *ngSwitchCase="TaskSortBy.DUE_DATE_O_L">{{ 'project-tasks-list.due-date-o-l' | translate }}</ng-container>
				</ng-container>
			</a>
			<mat-menu #tasksSortMenu="matMenu">
				<button mat-menu-item [class.text-primary]="taskSortBy === TaskSortBy.TITLE_A_Z" (click)="taskSortBy$$.next(TaskSortBy.TITLE_A_Z)">
					{{ 'project-tasks-list.title-a-z' | translate }}
				</button>
				<button mat-menu-item [class.text-primary]="taskSortBy === TaskSortBy.TITLE_Z_A" (click)="taskSortBy$$.next(TaskSortBy.TITLE_Z_A)">
					{{ 'project-tasks-list.title-z-a' | translate }}
				</button>
				<button
					mat-menu-item
					[class.text-primary]="taskSortBy === TaskSortBy.ASSIGNEE_A_Z"
					(click)="taskSortBy$$.next(TaskSortBy.ASSIGNEE_A_Z)"
				>
					{{ 'project-tasks-list.assignee-a-z' | translate }}
				</button>
				<button
					mat-menu-item
					[class.text-primary]="taskSortBy === TaskSortBy.ASSIGNEE_Z_A"
					(click)="taskSortBy$$.next(TaskSortBy.ASSIGNEE_Z_A)"
				>
					{{ 'project-tasks-list.assignee-z-a' | translate }}
				</button>
				<button
					mat-menu-item
					[class.text-primary]="taskSortBy === TaskSortBy.DUE_DATE_L_O"
					(click)="taskSortBy$$.next(TaskSortBy.DUE_DATE_L_O)"
				>
					{{ 'project-tasks-list.due-date-l-o' | translate }}
				</button>
				<button
					mat-menu-item
					[class.text-primary]="taskSortBy === TaskSortBy.DUE_DATE_O_L"
					(click)="taskSortBy$$.next(TaskSortBy.DUE_DATE_O_L)"
				>
					{{ 'project-tasks-list.due-date-o-l' | translate }}
				</button>
			</mat-menu>
		</span>
	</div>
	<div *ngIf="tasksListUI" class="flex-grow overflow-auto min-height-min-content pb-5">
		<ng-container *ngFor="let tasksList of tasksListUI">
			<div [class.d-none]="!tasksList.list.length">
				<h3 class="mat-caption mb-0 text-muted">{{ tasksList.title }} ({{ tasksList.list.length }})</h3>

				<ul class="list-unstyled">
					<li *ngFor="let task of tasksList.list" class="border-bottom py-2 pointer" (click)="openTaskEdit(task)">
						<div class="task-title d-flex align-items-center position-relative pb-1">
							<div>
								<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" class="task-icon">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
										<path
											d="M18,4 C19.1045695,4 20,4.8954305 20,6 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,6 C4,4.8954305 4.8954305,4 6,4 L18,4 Z M15.2,5.6 L8.8,5.6 C8.3581722,5.6 8,5.9581722 8,6.4 C8,6.8418278 8.3581722,7.2 8.8,7.2 L8.8,7.2 L15.2,7.2 C15.6418278,7.2 16,6.8418278 16,6.4 C16,5.9581722 15.6418278,5.6 15.2,5.6 L15.2,5.6 Z"
											fill="currentColor"
										></path>
									</g>
								</svg>
							</div>

							<h2 class="m-0 pr-5 text-truncate" [class.text-decoration-line-through]="task.done">
								{{ task.title }}
							</h2>

							<mat-checkbox
								color="accent"
								appEventStopPropagation
								class="pull-to-right d-flex"
								(change)="toggleTaskStatus(task)"
								[checked]="task.done"
							></mat-checkbox>
						</div>
						<div class="task-meta position-relative text-muted">
							<span class="mat-caption task-assignee-name">{{ task.assignee }}</span>
							<span class="mat-caption pull-to-right">{{ task.deadlineTimestamp | unixToMillis | date: 'dd.MM.yyyy' }}</span>
						</div>
					</li>
				</ul>
			</div>
		</ng-container>

		<div class="empty-state absolute-center text-center" *ngIf="!(tasks$ | async)?.length">
			<img class="mb-2 invert-color" src="assets/images/tt-empty-state.svg" alt="" />
			<p class="empty-state-text">{{ 'project-tasks-list.no-entries' | translate }}</p>
		</div>
	</div>

	<button class="create-task-btn" color="accent" mat-fab [routerLink]="['./create']">
		<mat-icon>add</mat-icon>
	</button>
</section>
