import { Component } from '@angular/core';
import { selectActiveProject } from '@store/selectors/route.selectors';
import { Project, Task, TaskRepeatType } from 'domain-entities';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { first, switchMap } from 'rxjs/operators';
import { selectProject } from '@store/selectors/projects.selectors';
import moment from 'moment';
import { selectUserId } from '@store/selectors/app.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuid } from 'uuid';
import { TrackingService } from '@injectables/services/tracking.service';
import { TasksTaskCreatedEventBuilder } from '@generated/events/TasksEvents.generated';
import { TasksService } from '@injectables/services/tasks/tasks.service';

@Component({
	selector: 'app-project-create-task',
	templateUrl: './project-task-create.component.html',
	styleUrls: ['./project-task-create.component.scss'],
})
export class ProjectTaskCreateComponent {
	projectId$: Observable<string> = this.store.select(selectActiveProject);
	project$: Observable<Project> = this.projectId$.pipe(
		switchMap((projectId) => this.store.select(selectProject, { projectId })),
	);
	isFormValid = false;
	newTask: Task = null;

	constructor(
		private readonly store: Store,
		private readonly tasksService: TasksService,
		private readonly router: Router,
		private readonly activatedRoute: ActivatedRoute,
		private readonly trackingService: TrackingService,
	) {}

	onFormValidationChange(isFormValid: boolean): void {
		this.isFormValid = isFormValid;
	}

	async createTask(): Promise<void> {
		const projectId = await this.projectId$.pipe(first()).toPromise();
		this.newTask.creatorId = await this.store.select(selectUserId).pipe(first()).toPromise();
		this.newTask.projectId = projectId;
		this.newTask.done = false;
		this.newTask.createdTimestamp = moment().unix();
		this.newTask.repeatType = this.newTask.repeatType || TaskRepeatType.NEVER;
		this.newTask.id = uuid();
		this.newTask.lastChangeByUser = this.newTask.creatorId;

		await this.tasksService.createTask(this.newTask);
		await this.trackingService.trackEvent(new TasksTaskCreatedEventBuilder({ projectId }));
		this.router.navigate(['../', this.newTask.id, 'edit'], { relativeTo: this.activatedRoute });
	}

	async onCancelCreateTask(): Promise<void> {
		await this.router.navigate(['../'], { relativeTo: this.activatedRoute });
	}

	onUpdateTask(task: Task): void {
		this.newTask = task;
	}
}
