import { InjectionToken } from '@angular/core';
import { environment } from '@env/environment';

export interface Environment {
	name: string;
	production: boolean;
	version: string;
	baseUrl: string;
	appUrl: string;
	app_dynamic_link: string;
	firebaseConfig: {
		apiKey: string;
		authDomain: string;
		databaseURL: string;
		projectId: string;
		storageBucket: string;
		messagingSenderId: string;
		appId: string;
		measurementId: string;
	};
	stripe: {
		public_key: string;
	};
	company_data_bucket: string;
	export_data_bucket: string;
	company_website_url: string;
	planning_board_url: string;
	external_chat_url: string;
	public_folders_bucket_name: string;
	zendesk_feedback_button_id: string;
	appBaseUrl: string;
	publicAssetsBucket: string;
	loopedIn: {
		workspace_id: string;
	};
	pspdfkit: {
		license_key: string;
	};
	phraseApp?: {
		ICEConfig: {
			projectId: string;
			phraseEnabled: boolean;
			prefix: string;
			suffix: string;
			fullReparse: boolean;
			autoLowercase: boolean;
		};
	};
	globalSearchElasticSearchUrl: string;
	restApiUrl: string;
	realtimeApiUrl: string;
}

export const ENVIRONMENT = new InjectionToken<Environment>('Environment Variables', {
	providedIn: 'root',
	factory: () => environment,
});
