import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutosizeModule } from 'ngx-autosize';
import { BaseFileService } from '@injectables/services/base-file.service';
import { ScrollEventModule } from '@modules/shared/directives/ngx-scroll-event/scroll.module';
import { ChatComponent } from './chat/chat.component';
import { TaggingDialogComponent } from './chat/tagging/tagging.component';
import { CdkDetailRowDirective } from './directives/cdk-detail-row.directive';
import { FileExplorerService } from '@injectables/services/file-explorer/file-explorer.service';
import { ApproveDialogComponent } from './file-explorer/modals/approve-dialog/approve-dialog.component';
import { ProjectSearchModule } from './project-search/project-search.module';
import { ProjectActionDialogComponent } from './project/project-action-dialog/project-action-dialog.component';
import { ProjectDuplicateDialogComponent } from './project/project-duplicate-dialog/project-duplicate-dialog.component';
import { ProjectMoveDialogComponent } from './project/project-move-dialog/project-move-dialog.component';
import { ProjectPrefillDialogComponent } from './project/project-prefill-dialog/project-prefill-dialog.component';
import { WorkComponent } from './work.component';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {
	MatLegacyPaginatorIntl as MatPaginatorIntl,
	MatLegacyPaginatorModule as MatPaginatorModule,
} from '@angular/material/legacy-paginator';
import { MatPaginatorIntImpl } from '@shared/i18n/mat-paginator-int-impl';
import { MatSortModule } from '@angular/material/sort';
import { ChatMessageComponent } from './chat/chat-message/chat-message.component';
import { ChatModule } from '@modules/features/chat/chat.module';
import { ProjectsModule } from '@modules/features/projects/projects.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AutoscrollModule } from '@modules/shared/directives/autoscroll/autoscroll.module';
import { ProjectInviteComponent } from './project-invite/project-invite.component';
import { PulsingCraftnoteIconModule } from '@modules/shared/components/pulsing-craftnote-icon/pulsing-craftnote-icon.module';
import { RouterModule } from '@angular/router';
import { SharedProjectsModule } from '@modules/features/projects/shared-projects.module';
import { ChatHeaderComponent } from './chat/chat-header/chat-header.component';
import { ChatSearchComponent } from './chat/chat-search/chat-search.component';
import { DummyMenuComponent } from './dummy-menu/dummy-menu.component';
import { ArchiveComponent } from './archive/archive.component';
import { SettingsPageHeaderModule } from '@modules/shared/components/settings-page-header/settings-page-header.module';
import { ProjectCreateDialogComponent } from './project/project-create-dialog/project-create-dialog.component';
import { ButtonLoaderModule, NamedIconModule } from '@craftnote/material-theme';
import { ProjectEditModule } from './project-edit/project-edit.module';
import { SharedFilesSectionModule } from './files-section/shared-files-section.module';
import { ProjectNotesModule } from '@modules/shared/components/project-notes/project-notes.module';
import { TasksModule } from './tasks/tasks.module';
import { ProjectMembersModule } from './project-members/project-members.module';
import { EmptyComponentModule } from '@modules/shared/components/empty-component/empty-component.module';
import { ProjectTasksListModule } from './project-tasks-list/project-tasks-list.module';
import { ProjectTaskCreateComponent } from './project-task-create/project-task-create.component';
import { TaskEditSharedModule } from '@modules/shared/components/task-edit/task-edit-shared.module';
import { TimeTrackingAddEditModule } from '@modules/shared/components/time-tracking-add-edit/time-tracking-add-edit.module';
import { MatIconSizeModule } from '@modules/shared/directives/mat-icon-size.module';
import { ViewContainerModule } from '@modules/shared/directives/view-container.module';
import { HasLimitModule } from '@modules/shared/directives/has-limit.module';
import { DarkOverlayModule } from '@modules/shared/components/dark-overlay/dark-overlay.module';
import { EmptyStateModule } from '@modules/shared/components/empty-state/empty-state.module';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { ColorPickerModule } from './project-edit/components/color-picker/color-picker.module';
import { AddEditCompanyEmployeeModule } from '@modules/shared/components/add-edit-company-employee/add-edit-company-employee.module';
import { ProjectsAreaModule } from '@modules/shared/components/projects-area/projects-area.module';
import { MemberFullNamePipeModule } from '@modules/shared/pipes/member-full-name/member-full-name-pipe.module';
import { LinkifyPipeModule } from '@modules/shared/pipes/linkify/linkify-pipe.module';
import { MessageDatePipeModule } from '@modules/shared/pipes/message-date/message-date-pipe.module';
import { ProjectMembersPipeModule } from '@modules/shared/pipes/project-members/project-members-pipe.module';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';

@NgModule({
	imports: [
		SharedModule,
		RouterModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		MatGridListModule,
		FormsModule,
		MatInputModule,
		MatButtonModule,
		MatCheckboxModule,
		MatSnackBarModule,
		MatDialogModule,
		MatAutocompleteModule,
		MatIconModule,
		MatChipsModule,
		MatRadioModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatDatepickerModule,
		MatDatetimepickerModule,
		MatNativeDatetimeModule,
		MatNativeDateModule,
		MatMenuModule,
		MatSlideToggleModule,
		BrowserModule,
		AutoscrollModule,
		AutosizeModule,
		ScrollEventModule,
		NgbModule,
		MatListModule,
		MatTableModule,
		ProjectSearchModule,
		MatButtonToggleModule,
		MatPaginatorModule,
		MatSortModule,
		ChatModule,
		ProjectsModule,
		ScrollingModule,
		SharedFilesSectionModule,
		SharedProjectsModule,
		PulsingCraftnoteIconModule,
		SettingsPageHeaderModule,
		ProjectEditModule,
		ProjectNotesModule,
		TasksModule,
		ProjectMembersModule,
		ProjectTasksListModule,
		EmptyComponentModule,
		ButtonLoaderModule,
		NamedIconModule,
		TaskEditSharedModule,
		TimeTrackingAddEditModule,
		MatIconSizeModule,
		ViewContainerModule,
		HasLimitModule,
		DarkOverlayModule,
		EmptyStateModule,
		PickerModule,
		NgxTrimDirectiveModule,
		ColorPickerModule,
		AddEditCompanyEmployeeModule,
		ProjectsAreaModule,
		MemberFullNamePipeModule,
		LinkifyPipeModule,
		MessageDatePipeModule,
		ProjectMembersPipeModule,
		VgCoreModule,
		VgControlsModule,
		VgOverlayPlayModule,
		VgBufferingModule,
	],
	declarations: [
		WorkComponent,
		ArchiveComponent,
		ChatComponent,
		ChatHeaderComponent,
		ChatSearchComponent,
		ProjectActionDialogComponent,
		ProjectPrefillDialogComponent,
		ProjectMoveDialogComponent,
		ProjectDuplicateDialogComponent,
		CdkDetailRowDirective,
		TaggingDialogComponent,
		ApproveDialogComponent,
		ChatMessageComponent,
		ProjectInviteComponent,
		DummyMenuComponent,
		ProjectCreateDialogComponent,
		ProjectTaskCreateComponent,
	],
	providers: [
		BaseFileService,
		FileExplorerService,
		{ provide: MatPaginatorIntl, useClass: MatPaginatorIntImpl },
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	exports: [WorkComponent, ChatComponent],
})
export class WorkModule {}
