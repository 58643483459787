<section class="project-internal-member d-flex flex-column h-100">
	<div class="p-3 d-flex flex-column flex-grow-1 overflow-auto">
		<div class="project-internal-member__header">
			<div class="d-flex align-items-center">
				<mat-icon class="pointer" (click)="openProjectListView()" data-cy="back-internal-members-overview">arrow_back </mat-icon>
				<div class="mat-body-2 mx-3 flex-grow-1 text-center" data-cy="internal-members-area">
					{{ 'projectMembers.internal.header' | translate }}
				</div>
			</div>
			<div class="my-3">
				<form [formGroup]="memberSearchForm" class="flex-grow-1 d-flex align-items-center chat-search-form">
					<mat-form-field appearance="fill" class="flex-grow-1">
						<input
							matInput
							formControlName="text"
							data-cy="internal-member-searchbox"
							[placeholder]="'projectMembers.internal.search' | translate"
							autocomplete="off"
						/>
						<div matSuffix class="d-flex">
							<button mat-icon-button *ngIf="searchText?.length > 0" (click)="onResetChatSearchForm()" type="reset">
								<mat-icon data-cy="internal-members-search-clear">clear</mat-icon>
							</button>
							<button mat-icon-button type="submit">
								<mat-icon data-cy="internal-members-search">search</mat-icon>
							</button>
						</div>
					</mat-form-field>
				</form>
				<div
					class="my-2 search-hint"
					[innerHTML]="'projectMembers.internal.hint' | translate: { companyName: (companyName$ | async) }"
				></div>
			</div>
		</div>

		<mat-checkbox *ngIf="(currentProject$ | async)?.projectType === ProjectType.FOLDER" [(ngModel)]="folderAndChildren" class="mb-2">
			{{ 'project-internal-member.apply-to-all-checkbox' | translate }}
		</mat-checkbox>

		<div class="project-internal-member__body flex-grow-1 overflow-auto d-flex flex-column">
			<ng-container *ngIf="filteredProjectMembers$ | async as projectMembers">
				<ng-container *ngFor="let member of projectMembers">
					<app-project-member-list-item
						*ngIf="selectIsMemberHasProjectInvitationsRemaining$(member) | async"
						[member]="member.entity"
						[invitationId]="member?.invitationId"
						[inviteStatus]="member?.invitedStatus"
						(addMember)="addMember($event)"
						(remindInvitation)="remindInvitation(member?.invitationId)"
						(resendInvitation)="resendInvitation($event)"
						(removeMember)="removeMember($event)"
						class="project-members__list-item d-block border-bottom"
					>
					</app-project-member-list-item>
				</ng-container>

				<div>
					<div class="mb-2 mt-4 no-project-remaining-paywall">
						<h1 class="m-0">{{ 'project-internal-member.no-project-remaining.list-title' | translate }}</h1>
						<button (click)="openNoProjectInvitationsRemainingPaywallDialog()" mat-button color="accent">
							{{ 'project-internal-member.no-project-remaining.list-learn-more' | translate }}
						</button>
					</div>

					<ng-container *ngFor="let member of projectMembers">
						<app-project-member-list-item
							*ngIf="!(selectIsMemberHasProjectInvitationsRemaining$(member) | async)"
							[member]="member.entity"
							[disabled]="true"
							[invitationId]="member?.invitationId"
							[inviteStatus]="member?.invitedStatus"
							class="project-members__list-item d-block border-bottom"
						>
						</app-project-member-list-item>
					</ng-container>
				</div>
				<div *ngIf="(isEmptyContainer$ | async) && searchText.length <= 0; then emptyFolder"></div>
			</ng-container>
		</div>
	</div>
	<div class="project-internal-member__footer px-3 pb-3" *ngIf="isOwner$ | async">
		<app-project-internal-invite-member></app-project-internal-invite-member>
	</div>

	<app-loader *ngIf="isLoading || (isInvitationsLoading$ | async)"></app-loader>
</section>

<ng-template #emptyFolder>
	<div class="project-internal-member__empty-container flex-grow-1 d-flex align-items-center justify-content-center text-center p-4">
		<div>
			<mat-icon class="mb-2 empty-folder-icon" svgIcon="empty-folder"></mat-icon>
			<div class="empty-text">
				{{ 'projectMembers.internal.emptyText' | translate }}
			</div>
		</div>
	</div>
</ng-template>
