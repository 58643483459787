<section class="tracked-time-pause-button-section">
	<div class="d-flex flex-wrap justify-content-between align-items-center w-100">
		<div class="align-items-center d-flex pr-2">
			<img alt="" class="d-inline-flex mr-3 img-fluid icon-image" src="assets/images/icons/time-tracking/pause.svg" />
			<span data-cy="break-time-entry">{{ 'time-tracking-pause-button.pause' | translate }}</span>
		</div>
		<div class="d-flex justify-content-around flex-grow-1 align-items-center flex-wrap">
			<mat-form-field appearance="outline" class="form-field-outline time-picker pt-3" floatLabel="always">
				<mat-label>{{ 'time-tracking-add-edit-form.start-time' | translate }}</mat-label>
				<app-time-picker [disabled]="disabled" [formControl]="timeRangeControl.controls.start"></app-time-picker>
			</mat-form-field>
			<span class="px-2">{{ 'time-tracking-pause-button.for' | translate }}</span>
			<div class="align-items-center d-flex">
				<button (click)="decrementDuration()" [disabled]="!duration || disabled" class="duration-button" mat-icon-button>
					<mat-icon [size]="20" color="accent" data-cy="reduce-break-time">remove_circle_outline</mat-icon>
				</button>
				<div class="duration-text">
					<ng-container *ngIf="!duration"> 0 {{ 'time-tracking-pause-button.min' | translate }} </ng-container>
					<ng-container *ngIf="durationFormatted(duration).hr">
						{{ durationFormatted(duration).hr }} {{ 'time-tracking-pause-button.hr' | translate }}
					</ng-container>
					<ng-container *ngIf="durationFormatted(duration).min">
						{{ durationFormatted(duration).min }} {{ 'time-tracking-pause-button.min' | translate }}
					</ng-container>
				</div>
				<button (click)="incrementDuration()" [disabled]="disabled || maxValueReached" class="duration-button" mat-icon-button>
					<mat-icon [size]="20" color="accent" data-cy="increase-break-time">add_circle_outline</mat-icon>
				</button>
			</div>
		</div>
	</div>
</section>
