<section class="task-edit-details-section p-3">
	<div class="d-flex align-items-center mb-3">
		<div class="d-flex align-items-center">
			<button (click)="onCancelCreateTask()" class="mr-3" mat-icon-button>
				<mat-icon class="close-btn-icon">clear</mat-icon>
			</button>

			<div class="m-0 d-inline-flex mat-headline font-weight-bold">
				{{ 'project.create-task.title' | translate }}
			</div>
		</div>

		<div class="ml-auto d-flex align-items-center">
			<button mat-flat-button color="accent" (click)="createTask()" [disabled]="!isFormValid">
				{{ 'project.create-task.cta' | translate }}
			</button>
		</div>
	</div>
	<app-task-edit-form
		*ngIf="project$ as project"
		(isFormValid)="onFormValidationChange($event)"
		(taskUpdated)="onUpdateTask($event)"
		[project]="project | async"
	>
	</app-task-edit-form>
</section>
