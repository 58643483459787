import { Inject, Injectable, NgZone } from '@angular/core';
import { WINDOW } from '@craftnote/shared-utils';
import { ClickIdInput } from '@injectables/services/click-id.service';
import { GlobalSearchLastInteraction } from '@modules/shared/components/global-search-dialog/global-search-dialog.service';
import { Store } from '@ngrx/store';
import { ProjectFilters } from '@shared/models/project-filters.model';
import { AppTheme } from '@store/reducers/app.reducer';
import { selectUserId } from '@store/selectors/app.selectors';
import { AppState } from '@store/state/app.state';
import { GroupByOption } from '@work/project-search/types/project-sort.types';
import { TrackedTime } from 'domain-entities';
import { isEmpty, isNil, keys } from 'lodash';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface LocalStorageKeyTypeMap {
	hasUserCreatedWorkTypeBefore?: boolean;
	notificationReminderTimestamp?: number;
	isShowStatusMessages?: 'show' | 'hide';
	theme?: AppTheme;
	project?: string;
	activeProjectFilters?: ProjectFilters;
	archivedProjectFilters?: ProjectFilters;
	projectArchived?: string;
	tab?: number;
	trackTimesInitialUpgradeDialog?: boolean;
	companyTemplatesPromotionalContent?: boolean;
	profileTracker?: string;
	invitationTracker?: 'company-invitation';
	firefoxBrowserSettingsVisit?: number;
	firefoxTocDeclineLastVisit?: number;
	phraseAppEditor?: boolean;
	projectListGroupBy?: GroupByOption;
	clickId?: ClickIdInput;
	globalSearchLastInteraction?: GlobalSearchLastInteraction;
	externalChat?: boolean;
	externalChatFirstTime?: boolean;
	timeTrackingLastPauseDuration?: Pick<TrackedTime, 'pauseStartTime' | 'pauseDuration'> | number;
	projectArchivationNudge?: number;
}

type LocalStorageKey = keyof LocalStorageKeyTypeMap;

const KEY_PREFIX = 'craftnote';
const KEY_SEPARATOR = '|';
const NO_USER_KEY = 'logged-out-user';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	localStorage: Storage = this.window?.localStorage;
	private readonly userData$$ = new ReplaySubject<LocalStorageKeyTypeMap>(1);
	private readonly userData$ = this.userData$$.asObservable().pipe(filter(Boolean));
	private userData: LocalStorageKeyTypeMap = null;
	private readonly userId$ = this.store.select(selectUserId).pipe(filter(Boolean));
	private isLocalStorageAvailable = !!this.localStorage;

	private entriesWaitingToBeFlushed$$ = new BehaviorSubject<LocalStorageKeyTypeMap>({});

	constructor(
		private readonly zone: NgZone,
		@Inject(WINDOW) private readonly window: Window,
		private readonly store: Store<AppState>,
	) {
		this.watchUnflushedValues();
		this.store.select(selectUserId).subscribe((userId) => {
			if (isNil(userId)) {
				this.userData$$.next(null);
				return;
			}
			this.init();
		});
	}

	async init(): Promise<void> {
		if (!this.isLocalStorageAvailable) {
			return;
		}

		await this.addUnflushedWritesToPendingEntries();
		await firstValueFrom(this.entriesWaitingToBeFlushed$$.pipe(filter(isEmpty)));
		await this.initializeUserData();
		this.listenLocalStorageChanges();
		this.listenUserDataChanges();
	}

	async get<K extends LocalStorageKey>(key: K): Promise<LocalStorageKeyTypeMap[K] | undefined> {
		if (!this.isLocalStorageAvailable) {
			return;
		}
		const data = await firstValueFrom(this.userData$);
		return data[key];
	}

	getSync<K extends LocalStorageKey>(key: K): LocalStorageKeyTypeMap[K] | undefined {
		if (!this.isLocalStorageAvailable) {
			return;
		}
		if (this.userData === null) {
			throw new Error('Local storage is not initialized yet');
		}
		return this.userData[key];
	}

	getRemoteConfig<T>(key: string): T | undefined {
		if (!this.isLocalStorageAvailable) {
			return;
		}

		try {
			return JSON.parse(this.localStorage.getItem(`remoteConfig.${key}`));
		} catch (e) {
			return undefined;
		}
	}

	async set<K extends LocalStorageKey>(key: K, value: LocalStorageKeyTypeMap[K]): Promise<void> {
		if (!this.isLocalStorageAvailable) {
			return;
		}
		const currentWaitingLocalStorageEntries = this.entriesWaitingToBeFlushed$$.value;
		this.entriesWaitingToBeFlushed$$.next({
			...currentWaitingLocalStorageEntries,
			...{ [key]: value },
		});
	}

	async remove(key: LocalStorageKey): Promise<void> {
		if (!this.isLocalStorageAvailable) {
			return;
		}
		const data = await firstValueFrom(this.userData$);
		const newData = { ...data };
		delete newData[key];
		await this.setUserData(newData);
	}

	isEntryPending(key: LocalStorageKey): Observable<boolean> {
		return this.entriesWaitingToBeFlushed$$.pipe(map((entries) => keys(entries).includes(key)));
	}

	private listenUserDataChanges(): void {
		this.userData$$.subscribe((userData) => {
			this.userData = userData;
		});
	}

	private async initializeUserData(): Promise<void> {
		const userData = await this.getUserData();
		this.userData$$.next(userData);
	}

	private async setUserData(data: LocalStorageKeyTypeMap): Promise<void> {
		const userKey = await this.getUserKey();
		this.localStorage.setItem(userKey, JSON.stringify(data));
		this.userData$$.next(data);
	}

	private async getUserData(userId?: string): Promise<LocalStorageKeyTypeMap> {
		const getUserKey = await this.getUserKey(userId);
		let data;
		try {
			data = this.localStorage.getItem(getUserKey);
		} catch (e) {
			data = null;
		}
		return data ? JSON.parse(data) : {};
	}

	private async getUserKey(userIdOverride?: string): Promise<string> {
		const userId = userIdOverride ?? (await firstValueFrom(this.userId$));
		return `${KEY_PREFIX}${KEY_SEPARATOR}${userId}`;
	}

	// Note: It is not intended to listen to changes that are made to localStorage within the same browsing context.
	// Will be used to add localStorage values from dev tools
	private listenLocalStorageChanges(): void {
		this.window.addEventListener('storage', (event: StorageEvent) =>
			this.zone.run(async () => {
				const userKey = await this.getUserKey();
				if (event.key !== userKey) {
					return;
				}
				try {
					const newUserData = JSON.parse(event.newValue ?? null);
					if (!isNil(newUserData)) {
						this.userData$$.next(newUserData);
					}
				} catch (error) {
					this.userData$$.next({});
				}
			}),
		);
	}

	private async addUnflushedWritesToPendingEntries(): Promise<void> {
		const unflushedValues = await this.getUserData(NO_USER_KEY);
		const currentWaitingLocalStorageEntries = this.entriesWaitingToBeFlushed$$.value;
		this.entriesWaitingToBeFlushed$$.next({
			...currentWaitingLocalStorageEntries,
			...unflushedValues,
		});
	}

	private watchUnflushedValues(): void {
		if (!this.isLocalStorageAvailable) {
			return;
		}
		combineLatest([this.entriesWaitingToBeFlushed$$, this.store.select(selectUserId)]).subscribe(
			async ([entries, userId]) => {
				if (isEmpty(entries)) {
					return;
				}

				if (!userId) {
					await this.setUnflushedUserData(entries);
					return;
				}
				const mergedData = { ...(await this.getUserData()), ...entries };
				await this.setUserData(mergedData);
				await this.setUnflushedUserData({});
				this.entriesWaitingToBeFlushed$$.next({});
			},
		);
	}

	private async setUnflushedUserData(data: LocalStorageKeyTypeMap): Promise<void> {
		const userKey = await this.getUserKey(NO_USER_KEY);
		this.localStorage.setItem(userKey, JSON.stringify(data));
	}
}
