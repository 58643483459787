import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TrackedTime } from 'domain-entities';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import {
	selectAllWorkTypeEntities,
	selectCompanyId,
	selectProfile,
	selectUserId,
} from '@store/selectors/app.selectors';
import { first, take } from 'rxjs/operators';
import moment from 'moment';
import { getMemberFullName } from '@craftnote/shared-utils';
import { getTrackedTimePauseFromLocalStorageValue } from '@modules/shared/components/time-tracking-add-edit/components/functions/time-tracking-add-edit.functions';
import { LocalStorageService } from '@injectables/services/local-storage.service';

@Component({
	selector: 'app-tracked-time-add-edit-panel',
	templateUrl: './tracked-time-add-edit-panel.component.html',
	styleUrls: ['./tracked-time-add-edit-panel.component.scss'],
})
export class TrackedTimeAddEditPanelComponent {
	@Output() closePanel = new EventEmitter<void>();
	@Input() showProjectRedirection = true;
	@Input() showAssigneePaywall = true;
	trackedTime: TrackedTime;
	private projectId: string;

	constructor(
		private readonly store: Store<AppState>,
		private readonly localStorageService: LocalStorageService,
	) {}

	async init(projectId: string, trackedTime?: TrackedTime): Promise<void> {
		this.projectId = projectId;

		if (trackedTime) {
			this.trackedTime = { ...trackedTime };
		} else {
			const companyId = await this.store.select(selectCompanyId).pipe(take(1)).toPromise();
			const currentUserId = await this.store.select(selectUserId).pipe(first()).toPromise();
			const userProfile = await this.store.select(selectProfile).pipe(first()).toPromise();
			const workTypeEntities = await this.store
				.select(selectAllWorkTypeEntities)
				.pipe(take(1))
				.toPromise();
			const arbeitszeitWorkType = Object.values(workTypeEntities).find(
				(workType) => workType.name === 'Arbeitszeit',
			);
			const hasUserCreatedWorkTypeBefore = await this.localStorageService.get(
				'hasUserCreatedWorkTypeBefore',
			);
			const workTypeId = hasUserCreatedWorkTypeBefore ? arbeitszeitWorkType.id : null;
			const workTypeName = hasUserCreatedWorkTypeBefore ? arbeitszeitWorkType.name : null;
			const localStoragePause = getTrackedTimePauseFromLocalStorageValue(
				await this.localStorageService.get('timeTrackingLastPauseDuration'),
			);
			this.trackedTime = {
				id: null,
				projectId: this.projectId,
				companyId: companyId,
				creatorId: currentUserId,
				creationTime: 0,
				startTime: moment().set('hour', 8).set('minute', 0).set('second', 0).unix(),
				pauseDuration: localStoragePause.pauseDuration ?? 3600,
				pauseStartTime: localStoragePause.pauseStartTime,
				workTypeId: workTypeId,
				workTypeName: workTypeName,
				state: 'stopped',
				userName: getMemberFullName(userProfile),
				userId: currentUserId,
				endTime: moment().set('hour', 17).set('minute', 0).set('second', 0).unix(),
			};
		}
	}
}
