import { FirestoreConnector, RequiredKey } from '@craftnote/shared-injectables';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isTask, Task } from 'domain-entities';
import { DataQueryCondition, EntityChanges } from '@craftnote/shared-utils';

const TASK_REQUIRED_KEYS: RequiredKey<Task>[] = ['id', 'title', 'projectId', 'creatorId'];

@Injectable({
	providedIn: 'root',
})
export class TasksConnector {
	static readonly COLLECTION_NAME = 'tasks';

	constructor(private readonly connector: FirestoreConnector) {}

	getTasksByProjectId(projectId: string): Observable<Task[]> {
		const condition: DataQueryCondition<Task> = {
			field: 'projectId',
			operator: '==',
			value: projectId,
		};

		return this.connector.watchDocuments(TasksConnector.COLLECTION_NAME, [condition]);
	}

	watchTasks(companyId: string, taskIds: string[]): Observable<Task[]> {
		return this.connector.watchDocumentsBatches<Task>(
			TasksConnector.COLLECTION_NAME,
			taskIds,
			companyId,
		);
	}

	watchCompanyTasks(companyId: string, startAfter: number): Observable<Task[]> {
		const conditions: DataQueryCondition<Task>[] = [
			{
				field: 'companyId',
				operator: '==',
				value: companyId,
			},
		];
		if (startAfter) {
			conditions.push({
				field: 'lastEditedTimestamp',
				operator: '>=',
				value: startAfter,
			});
		}
		return this.connector.watchDocuments<Task>(TasksConnector.COLLECTION_NAME, conditions);
	}

	watchCompanyTasksEntityChanges(
		companyId: string,
		startAfter: number,
	): Observable<EntityChanges<Task>> {
		const conditions: DataQueryCondition<Task>[] = [
			{
				field: 'companyId',
				operator: '==',
				value: companyId,
			},
		];
		if (startAfter) {
			conditions.push({
				field: 'lastEditedTimestamp',
				operator: '>=',
				value: startAfter,
			});
		}
		return this.connector.watchDocumentsChanges<Task>(TasksConnector.COLLECTION_NAME, conditions);
	}

	getTaskById(id: string): Observable<Task> {
		return this.connector.watchDocument<Task>(TasksConnector.COLLECTION_NAME + '/' + id, isTask);
	}

	updateTask(task: Partial<Task>): Promise<void> {
		return this.connector.updateDocumentPartial<Task>(
			TasksConnector.COLLECTION_NAME,
			task.id,
			task,
			TASK_REQUIRED_KEYS,
		);
	}

	createTask(task: Task): Promise<void> {
		if (task?.projectId === null) {
			// Logging for CN-6159
			throw new Error('createTask: Project ID cannot be null. Task:' + task);
		}
		return this.connector.create(TasksConnector.COLLECTION_NAME, task.id, task, isTask);
	}

	deleteTask(taskId: string): Promise<void> {
		return this.connector.deleteDocument(TasksConnector.COLLECTION_NAME, taskId);
	}
}
