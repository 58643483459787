import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Optional,
	Self,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	ControlValueAccessor,
	NgControl,
	UntypedFormControl,
	ValidationErrors,
	Validator,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { isDate, isNil, noop } from 'lodash';
import moment from 'moment';

// TODO:: Add support for seconds
@Component({
	selector: 'app-time-picker',
	templateUrl: './time-picker.component.html',
	styleUrls: ['./time-picker.component.scss'],
	providers: [{ provide: MatFormFieldControl, useExisting: TimePickerComponent }],
})
export class TimePickerComponent
	implements
		MatFormFieldControl<Date>,
		ControlValueAccessor,
		OnDestroy,
		OnInit,
		Validator,
		OnChanges
{
	static nextId = 0;
	@Input() max: Date = null;

	@ViewChild('hoursInputRef', { static: true }) hoursInputRef: ElementRef<HTMLInputElement>;
	@ViewChild('minutesInputRef', { static: true }) minutesInputRef: ElementRef<HTMLInputElement>;

	private onChange: Function = null;

	private onTouch: Function = noop;

	lastFocusedInput: 'hours-input' | 'minutes-input' = 'hours-input';

	stateChanges = new Subject<void>();

	focused = false;

	errorState = false;

	controlType = 'time-picker-form';

	@HostBinding('class.floating') get shouldLabelFloat(): boolean {
		return this.focused || !this.empty;
	}

	@HostBinding() id = `time-picker-${TimePickerComponent.nextId++}`;

	@HostBinding('[attr.aria-describedby]') describedBy = '';

	get empty(): boolean {
		return isNil(this.value);
	}

	@Input()
	get placeholder(): string {
		return this._placeholder;
	}

	set placeholder(plh) {
		this._placeholder = plh;
		this.stateChanges.next();
	}

	private _placeholder: string;

	@Input()
	get required(): boolean {
		return this._required;
	}

	set required(req) {
		this._required = coerceBooleanProperty(req);
		this.stateChanges.next();
	}

	private _required = false;

	@Input()
	get disabled(): boolean {
		return this._disabled;
	}

	set disabled(dis) {
		this._disabled = coerceBooleanProperty(dis);
		this.stateChanges.next();
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	private _disabled = false;

	value: Date;

	constructor(
		private readonly fm: FocusMonitor,
		private readonly elRef: ElementRef,
		private readonly cdr: ChangeDetectorRef,
		@Optional() @Self() public readonly ngControl: NgControl,
	) {
		if (this.ngControl != null) {
			this.ngControl.valueAccessor = this;
		}

		fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
			this.focused = !!origin;
			this.stateChanges.next();
		});
	}

	ngOnInit(): void {
		this.ngControl.control.setValidators([this.validate.bind(this)]);
		this.ngControl.control.updateValueAndValidity({ emitEvent: false });
	}

	ngOnDestroy(): void {
		this.stateChanges.complete();
		this.fm.stopMonitoring(this.elRef.nativeElement);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.max?.currentValue) {
			this.ngControl.control.updateValueAndValidity({ emitEvent: false });
		}
	}

	private emitChanges(): void {
		this.onChange(this.value);
		this.onTouch();
		this.stateChanges.next();
	}

	setDescribedByIds(ids: string[]): void {
		this.describedBy = ids.join(' ');
	}

	onContainerClick(_event: MouseEvent): void {
		// Dont remove me
	}

	onClickIncrementButton(): void {
		if (this.disabled) {
			return;
		}
		if (this.lastFocusedInput === 'hours-input') {
			this.incrementHour();
		}
		if (this.lastFocusedInput === 'minutes-input') {
			this.incrementMinute();
		}
	}

	onClickDecrementButton(): void {
		if (this.disabled) {
			return;
		}

		if (this.lastFocusedInput === 'hours-input') {
			this.decrementHour();
		}
		if (this.lastFocusedInput === 'minutes-input') {
			this.decrementMinute();
		}
	}

	private moveCursorToEnd(element: HTMLInputElement): void {
		setTimeout(() => {
			element.selectionStart = element.selectionEnd = element.value.length;
		});
	}

	private get midNightTime(): Date {
		return moment().startOf('day').toDate();
	}

	private checkMaxTimeValidation(time: Date): null | { maxTime: true } {
		if (!this.max) {
			return null;
		}
		return moment(time).isBefore(moment(this.max)) ? null : { maxTime: true };
	}

	validate({ value }: UntypedFormControl): ValidationErrors {
		let errors = {};
		if (this.checkMaxTimeValidation(value)) {
			errors = { ...errors, ...this.checkMaxTimeValidation(value) };
		}

		if (Object.keys(errors).length) {
			this.errorState = true;
			return errors;
		} else {
			this.errorState = false;
			return null;
		}
	}

	onHourInputChange(hours: string): void {
		const parsedInput = Number.parseInt(hours, 10);
		if (this.isValidHours(parsedInput)) {
			const updatedValue = this.value || this.midNightTime;
			updatedValue.setHours(parsedInput);
			this.value = new Date(updatedValue.getTime());
			this.emitChanges();
		}
	}

	onMinutesInputChange(minutes: string): void {
		const parsedInput = Number.parseInt(minutes, 10);
		if (this.isValidMinutes(parsedInput)) {
			const updatedValue = this.value || this.midNightTime;
			updatedValue.setMinutes(parsedInput);
			this.value = new Date(updatedValue.getTime());
			this.emitChanges();
		}
	}

	incrementHour(event?: Event): void {
		this.lastFocusedInput = 'hours-input';
		let hours = this.value ? this.value.getHours() : 0;
		hours++;
		this.onHourInputChange(String(hours));
		if (event) {
			this.moveCursorToEnd(event.target as HTMLInputElement);
		}
	}

	decrementHour(event?: Event): void {
		this.lastFocusedInput = 'hours-input';
		let hours = this.value ? this.value.getHours() : 1;
		hours--;
		this.onHourInputChange(String(hours));
		if (event) {
			this.moveCursorToEnd(event.target as HTMLInputElement);
		}
	}

	incrementMinute(event?: Event): void {
		this.lastFocusedInput = 'minutes-input';
		let minutes = this.value ? this.value.getMinutes() : 0;
		minutes++;
		this.onMinutesInputChange(String(minutes));
		if (event) {
			this.moveCursorToEnd(event.target as HTMLInputElement);
		}
	}

	decrementMinute(event?: Event): void {
		this.lastFocusedInput = 'minutes-input';
		let minutes = this.value ? this.value.getMinutes() : 1;
		minutes--;
		this.onMinutesInputChange(String(minutes));
		if (event) {
			this.moveCursorToEnd(event.target as HTMLInputElement);
		}
	}

	private isValidHours(hours: number): boolean {
		return hours > -1 && hours < 24;
	}

	private isValidMinutes(minutes: number): boolean {
		return minutes > -1 && minutes < 60;
	}

	writeValue(value: Date): void {
		this.value = isDate(value) ? value : null;
		this.cdr.detectChanges();
	}

	registerOnChange(fn: Function): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onTouch = fn;
	}
}
