<div class="sidebar h-100">
	<div *ngIf="project" [class.d-none]="!isProjectVisible()" class="form-group container">
		<form (submit)="onSubmit()" [formGroup]="projectForm" class="form" role="form">
			<div class="d-flex justify-content-between mt-3">
				<div>
					<h1 data-cy="project-details-header">{{ titleKey | translate }}</h1>
				</div>
				<div>
					<button
						*ngIf="(isOwnerOrSupervisorOfProject$ | async) || !(isExternalMemberOfProject$ | async)"
						[disabled]="!projectForm.valid"
						class="float-right"
						color="accent"
						data-cy="details-project-update"
						mat-flat-button
						type="submit"
					>
						{{ 'task.update' | translate }}
					</button>
				</div>
			</div>
			<div>
				<mat-form-field class="w-100 mt-3">
					<input
						data-cy="details-project-name"
						formControlName="name"
						matInput
						placeholder="{{ nameKey | translate }}"
						trim="blur"
						type="text"
					/>
					<mat-error *ngIf="projectForm.controls.name.errors?.required">
						{{ 'error.required' | translate }}
					</mat-error>
				</mat-form-field>
			</div>
			<div>
				<mat-form-field class="w-50 pr-2 mt-3" color="accent">
					<input
						(click)="startPicker.open()"
						[matDatepicker]="startPicker"
						[max]="projectForm.controls.endDate.value"
						[placeholder]="'project.startDate' | translate"
						class="pointer"
						data-cy="details-project-startdate"
						formControlName="startDate"
						matInput
						readonly
						type="text"
					/>
					<button
						(click)="projectForm.controls.startDate.setValue(null)"
						*ngIf="projectForm.controls.startDate.value"
						mat-icon-button
						matSuffix
					>
						<mat-icon>close</mat-icon>
					</button>
					<mat-datepicker-toggle [for]="startPicker" matSuffix>
						<img alt="Calendar" matDatepickerToggleIcon src="assets/images/icons/calendar.svg" />
					</mat-datepicker-toggle>
					<mat-datepicker #startPicker></mat-datepicker>
				</mat-form-field>
				<mat-form-field class="w-50 pl-2 mt-3" color="accent">
					<input
						(click)="endPicker.open()"
						[matDatepicker]="endPicker"
						[min]="projectForm.controls.startDate.value"
						[placeholder]="'project.endDate' | translate"
						class="pointer"
						data-cy="details-project-enddate"
						formControlName="endDate"
						matInput
						readonly
						type="text"
					/>
					<button
						(click)="projectForm.controls.endDate.setValue(null)"
						*ngIf="projectForm.controls.endDate.value"
						mat-icon-button
						matSuffix
					>
						<mat-icon>close</mat-icon>
					</button>
					<mat-datepicker-toggle [for]="endPicker" matSuffix>
						<img alt="Calendar" matDatepickerToggleIcon src="assets/images/icons/calendar.svg" />
					</mat-datepicker-toggle>
					<mat-datepicker #endPicker></mat-datepicker>
				</mat-form-field>
			</div>
			<div>
				<mat-form-field class="w-100 mt-3">
					<input
						data-cy="details-project-id"
						formControlName="orderNumber"
						matInput
						placeholder="{{ 'project.order_number' | translate }}"
						trim="blur"
					/>
				</mat-form-field>
			</div>
			<div *ngIf="project.projectType === ProjectType.PROJECT && (isExternalMemberOfProject$ | async) === false">
				<mat-form-field class="w-100 mt-3">
					<input
						(click)="openProjectStatusDialog()"
						[value]="projectStatusName$ | async"
						data-cy="details-project-status"
						matInput
						placeholder="{{ 'project.status' | translate }}"
						readonly
						type="text"
					/>
				</mat-form-field>
			</div>
			<div class="mt-3">
				<app-project-notes (note)="onNoteUpdated($event)" [projectId]="project.id">
					<mat-form-field class="w-100">
						<input
							data-cy="details-project-notes"
							formControlName="noteContent"
							matInput
							placeholder="{{ 'members.note.header' | translate }}"
							readonly
							type="text"
						/>
					</mat-form-field>
				</app-project-notes>
			</div>
			<div>
				<mat-form-field class="w-100 mt-3">
					<input
						data-cy="details-project-street"
						formControlName="street"
						matInput
						placeholder="{{ 'project.street' | translate }}"
						trim="blur"
						type="text"
					/>
				</mat-form-field>
			</div>
			<div>
				<mat-form-field class="w-25 mt-3">
					<input
						data-cy="details-project-zipcode"
						formControlName="zipcode"
						matInput
						name="zipcode"
						placeholder="{{ 'project.zipcode' | translate }}"
						trim="blur"
						type="text"
					/>
				</mat-form-field>
				<mat-form-field class="w-75 pl-2 mt-3">
					<input
						data-cy="details-project-city"
						formControlName="city"
						matInput
						placeholder="{{ 'project.city' | translate }}"
						trim="blur"
						type="text"
					/>
				</mat-form-field>
			</div>
			<div>
				<mat-form-field class="w-100 mt-3">
					<input
						data-cy="details-project-country"
						formControlName="country"
						matInput
						placeholder="{{ 'project.country' | translate }}"
						trim="blur"
						type="text"
					/>
				</mat-form-field>
			</div>

			<div>
				<div class="mt-3">
					<app-color-picker
						(projectColorTagChange)="projectForm.controls.colorTag.setValue($event)"
						[isOwnerOrSupervisor]="isOwnerOrSupervisorOfProject$ | async"
						[projectColorTag]="projectForm.controls.colorTag.value"
						[project]="project"
						formControlName="colorTag"
						ngDefaultControl
					></app-color-picker>
				</div>
			</div>

			<div class="mt-5">
				<div (click)="openContactsList()" class="option-wrap">
					<div class="option-wrap-inner">
						<span>{{ 'project.titleContactInfo' | translate }}</span>
						<em class="material-icons float-right arrow mr-4 enable-events" data-cy="details-project-contacts">keyboard_arrow_right</em>
					</div>
				</div>
				<div (click)="openBillingAddressDialog()" class="option-wrap">
					<div class="option-wrap-inner">
						<span>{{ 'project.title_billing_address' | translate }}</span>
						<em class="material-icons float-right arrow mr-4 enable-events" data-cy="details-project-billing">keyboard_arrow_right</em>
					</div>
				</div>
			</div>

			<div class="mt-5">
				<div>
					<h1>{{ 'project.actions.title' | translate }}</h1>
				</div>
			</div>

			<div class="mt-3">
				<div (click)="restoreProject()" *ngIf="archived && isOwnerOrInternalSupervisorOfProject$ | async" class="option-wrap">
					<div class="option-wrap-inner">
						<span data-cy="details-project-archive"> {{ 'project.actions.restore' | translate }}</span>
					</div>
				</div>

				<div
					(click)="archiveProject()"
					*ngIf="!archived && isOwnerOrInternalSupervisorOfProject$ | async"
					[matTooltip]="isActiveFolderProjectInArchive ? ('project.actions.archive.activeFolderInArchive' | translate) : null"
					class="option-wrap"
				>
					<div class="option-wrap-inner">
						<span data-cy="details-project-archive"> {{ 'project.actions.archive.title' | translate }}</span>
					</div>
				</div>

				<div (click)="showLeaveProjectDialog()" *ngIf="canUserLeaveProject$ | async" class="option-wrap">
					<div class="option-wrap-inner">
						<span *ngIf="project.projectType === ProjectType.PROJECT" data-cy="details-project-leave">{{
							'project.leave_project' | translate
						}}</span>
						<span *ngIf="project.projectType === ProjectType.FOLDER" data-cy="details-folder-leave">{{
							'folder.leave_folder' | translate
						}}</span>
					</div>
				</div>
				<div
					(click)="deleteProject()"
					*ngIf="isOwnerOrInternalSupervisorOfProject$ | async"
					[matTooltip]="isActiveFolderProjectInArchive ? ('project.actions.delete.activeFolderInArchive' | translate) : null"
					class="option-wrap"
				>
					<div class="option-wrap-inner">
						<span data-cy="details-project-delete">{{ 'project.actions.delete.title' | translate }}</span>
					</div>
				</div>
				<div
					(click)="exportData()"
					*ngIf="(isOwnerOrInternalSupervisorOfProject$ | async) && project.projectType === ProjectType.PROJECT"
					class="option-wrap"
				>
					<div class="option-wrap-inner">
						<span data-cy="details-project-data-export">{{ 'export.project.title' | translate }}</span>
					</div>
				</div>
				<div
					(click)="exportChat()"
					*ngIf="(isOwnerOrInternalSupervisorOfProject$ | async) && project.projectType === ProjectType.PROJECT"
					class="option-wrap"
				>
					<div class="option-wrap-inner">
						<span data-cy="details-project-chat-export">{{ 'export.chat.title' | translate }}</span>
					</div>
				</div>
			</div>
		</form>
	</div>

	<app-project-contacts-list (close)="contactsListVisible = false" *ngIf="contactsListVisible"></app-project-contacts-list>

	<app-project-billing-address
		(onBack)="closeBillingAddressDialog()"
		(onSave)="saveBillingAddress($event)"
		*ngIf="project && billingAddressViewVisible"
		[billingAddress]="billingAddressModel"
		[modifiable]="isOwnerOrSupervisorOfProject$ | async"
	></app-project-billing-address>
</div>
