import { createEffect } from '@ngrx/effects';
import { Inject, Injectable } from '@angular/core';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { DOCUMENT } from '@angular/common';
import { FirebaseApp } from '@angular/fire/compat';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class WindowVisibilityEffects {
	constructor(
		@Inject(DOCUMENT) private readonly documentRef: Document,
		private readonly remoteConfig: RemoteConfig,
		private readonly app: FirebaseApp,
	) {}

	/**
	 * This effect only exists to maintain some code that needs to be executed once at the
	 * app start. This is the easiest way to wrap such code in a dedicated file.
	 */
	turnDatabaseOfflineIfHidden$ = createEffect(
		() =>
			of(null).pipe(
				tap(() => {
					if (!this.remoteConfig.getValue('feature_no_data_on_invisible_tabs')) {
						return;
					}
					this.documentRef.addEventListener('visibilitychange', () => {
						if (this.documentRef.hidden) {
							this.app.firestore().disableNetwork();
							this.app.database().goOffline();
						} else {
							this.app.firestore().enableNetwork();
							this.app.database().goOnline();
						}
					});
				}),
			),
		{ dispatch: false },
	);
}
