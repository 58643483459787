import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { noop } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const DEFAULT_STEP = 300;
const MAX_VALUE = 32400;
const MIN_VALUE = 900;

interface PauseButtonDataType {
	start: Date;
	duration: number;
}

@Component({
	selector: 'app-tracked-time-pause-button',
	templateUrl: './tracked-time-pause-button.component.html',
	styleUrls: ['./tracked-time-pause-button.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: TrackedTimePauseButtonComponent,
		},
	],
})
export class TrackedTimePauseButtonComponent implements ControlValueAccessor, OnInit, OnDestroy {
	private _required = false;
	private valueListener: ((range: PauseButtonDataType) => void)[] = [];
	private destroy$ = new Subject();

	registerOnTouched: () => void = noop;

	@Input()
	get required(): boolean {
		return this._required;
	}

	set required(value: boolean) {
		this._required = coerceBooleanProperty(value);
	}

	private _disabled = false;

	get duration(): number {
		return this.timeRangeControl.controls['duration'].value;
	}

	@Input()
	get disabled(): boolean {
		return this._disabled;
	}

	set disabled(value: boolean) {
		this._disabled = coerceBooleanProperty(value);
	}

	timeRangeControl = this.formBuilder.group({
		start: [null],
		duration: [0],
	});

	ngOnInit(): void {
		this.updateListeners();
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	registerOnChange(listener: (range: PauseButtonDataType) => void): void {
		this.valueListener.push(listener);
	}

	get maxValueReached(): boolean {
		return this.timeRangeControl.value.duration >= MAX_VALUE;
	}

	constructor(private readonly formBuilder: UntypedFormBuilder) {}

	writeValue(range: PauseButtonDataType): void {
		this.timeRangeControl.setValue(range);
	}

	markAsTouched(): void {}

	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	decrementDuration(): void {
		this.markAsTouched();
		const durationControl = this.timeRangeControl.controls['duration'];
		const offsetFromPreviousFullStepValue = durationControl.value % DEFAULT_STEP;
		const nextHigherFullStepValue =
			durationControl.value + ((DEFAULT_STEP - offsetFromPreviousFullStepValue) % DEFAULT_STEP);
		let nextValue = nextHigherFullStepValue - DEFAULT_STEP;
		// Prevent values between the minimum value and 0
		nextValue = nextValue < MIN_VALUE ? 0 : nextValue;
		durationControl.setValue(nextValue);
	}

	incrementDuration(): void {
		this.markAsTouched();
		const durationControl = this.timeRangeControl.controls['duration'];
		const offsetFromPreviousFullStepValue = durationControl.value % DEFAULT_STEP;
		const nextLowerFullStepValue = durationControl.value - offsetFromPreviousFullStepValue;
		let nextValue = nextLowerFullStepValue + DEFAULT_STEP;
		// Prevent values between the minimum value and 0
		nextValue = nextValue < MIN_VALUE ? MIN_VALUE : nextValue;
		durationControl.setValue(nextValue);
	}

	durationFormatted(duration: number): { hr: number; min: number } {
		return {
			hr: Math.floor(duration / 3600),
			min: Math.floor((duration % 3600) / 60),
		};
	}

	private updateListeners(): void {
		this.timeRangeControl.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((value) => this.valueListener.forEach((listener) => listener(value)));
	}
}
