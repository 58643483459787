import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TASKS } from '@shared/constants/firebase';
import { Project, Task, TaskTime } from 'domain-entities';
import { ProjectType } from '@shared/models/project.type';
import { forkJoin, Observable, of } from 'rxjs';
import { EntityChanges } from '@craftnote/shared-utils';
import { chunk, flattenDeep } from 'lodash';
import { catchError, first, map } from 'rxjs/operators';
import { TaskTimeConnector } from '@shared/firebase/connectors/firestore/collections/task-time/task-time.connector';
import { ErrorHandlerService } from '@injectables/services/errors/error-handler.service';

// TODO:: Rewrite this service
@Injectable({
	providedIn: 'root',
})
export class TasksDashboardService {
	constructor(
		private readonly db: AngularFirestore,
		private readonly taskTimeConnector: TaskTimeConnector,
		private readonly errorHandlerService: ErrorHandlerService,
	) {}

	getAllTasksByProjects(projects: Project[]): Promise<Task[]> {
		const projectIds = chunk(
			projects
				.filter((project) => project.projectType === ProjectType.PROJECT)
				.map((project) => project.id),
			10,
		);

		return forkJoin(
			projectIds.map((projectIdsChunk) =>
				this.db
					.collection<Task>(TASKS, (ref) => ref.where('projectId', 'in', projectIdsChunk))
					.valueChanges()
					.pipe(
						first(),
						catchError((error) => {
							this.errorHandlerService.handleError(error);
							return of([]);
						}),
					),
			),
		)
			.pipe(map((tasks) => flattenDeep(tasks)))
			.toPromise();
	}

	getTaskTimesById(taskId: string): Observable<EntityChanges<TaskTime>> {
		return this.taskTimeConnector.watchTasksTimesOfTask(taskId);
	}

	createTaskTime(taskTime: TaskTime): Promise<void> {
		return this.taskTimeConnector.createTaskTime(taskTime);
	}
}
