import { isNil } from 'lodash';
import { TrackedTime } from 'domain-entities';

export function getTrackedTimePauseFromLocalStorageValue(
	localStorageValue: number | Pick<TrackedTime, 'pauseStartTime' | 'pauseDuration'> | undefined,
): Pick<TrackedTime, 'pauseStartTime' | 'pauseDuration'> {
	if (isNil(localStorageValue)) {
		return { pauseStartTime: null, pauseDuration: 0 };
	}
	if (typeof localStorageValue === 'number') {
		return { pauseStartTime: null, pauseDuration: 0 };
	}
	return localStorageValue as Pick<TrackedTime, 'pauseStartTime' | 'pauseDuration'>;
}
