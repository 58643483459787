<section class="task-edit-form-section pt-1">
	<form [formGroup]="taskEditForm">
		<input formControlName="id" class="d-none" />

		<mat-form-field appearance="outline" class="w-100 form-field-outline" floatLabel="always">
			<mat-label>{{ 'task.title' | translate }}</mat-label>
			<input trim="blur" formControlName="title" matInput />
			<mat-error>{{ 'error.required' | translate }}</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" class="w-100 form-field-outline" floatLabel="always">
			<mat-label>{{ 'dashboard.my-tasks.assignee' | translate }}</mat-label>
			<mat-icon *ngIf="assigneeIdControlValue" (click)="clearAssigneeId()" matSuffix>close</mat-icon>
			<input [matAutocomplete]="assigneesAutoComplete" #assigneeInput formControlName="assigneeId" matInput type="text" />
			<mat-autocomplete
				#assigneesAutoComplete="matAutocomplete"
				(optionSelected)="onAssigneeIdSelected($event)"
				[displayWith]="assigneesAutoCompleteDisplayWithBound"
			>
				<mat-option *ngFor="let member of projectMembersAcceptedFiltered$ | async" [value]="member.id">
					{{ member.text }}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>

		<mat-form-field appearance="outline" class="w-100 form-field-outline" floatLabel="always" color="accent">
			<mat-label>{{ 'task.deadline' | translate }}</mat-label>
			<mat-icon color="accent" fontSet="material-icons-sharp" matSuffix>calendar_today</mat-icon>
			<mat-datetimepicker #deadlineDateTimePickerRef openOnFocus="true" timeInterval="1" type="datetime"></mat-datetimepicker>
			<input [matDatetimepicker]="deadlineDateTimePickerRef" autocomplete="false" formControlName="deadlineTimestamp" matInput readonly />
		</mat-form-field>

		<mat-form-field
			appearance="outline"
			class="w-100 form-field-outline mb-3"
			floatLabel="always"
			[class.d-none]="!(isRecurringTaskEnabled$ | async)"
		>
			<mat-label>{{ 'task.repeat' | translate }}</mat-label>
			<mat-select formControlName="repeatType" [disabled]="!(isRecurringTaskEnabled$ | async)">
				<mat-option *ngFor="let repeatType of taskRepeatTypes" [value]="repeatType.value">
					{{ repeatType.text }}
				</mat-option>
			</mat-select>
			<mat-icon color="accent" matSuffix>keyboard_arrow_down</mat-icon>
			<mat-hint>
				<span class="font-weight-bold">{{ 'dashboard.my-tasks.repeat-type.hint' | translate }}</span>
				{{ 'dashboard.my-tasks.repeat-type.hint-description' | translate }}
			</mat-hint>
		</mat-form-field>

		<mat-form-field appearance="outline" class="w-100 form-field-outline" floatLabel="always">
			<mat-label>{{ 'task.description' | translate }}</mat-label>
			<textarea class="resize-none" formControlName="description" matInput rows="5"></textarea>
		</mat-form-field>

		<!--Only show completed checkbox when we have task-->
		<div class="task-completed-field p-3 rounded-top shadow-lg" *ngIf="task?.id">
			<mat-checkbox formControlName="done" color="accent">{{ 'task.mark-as-completed' | translate }}</mat-checkbox>
		</div>
	</form>
</section>
