export interface TrackedTimeAddEditFormPauseValue {
	start: Date;
	duration: number;
}

export enum TrackedTimeAddEditErrors {
	INVALID_PAUSE_START = 'time-tracking-add-edit-form.errors.invalid_pause_start',
	PAUSE_ENDS_AFTER_END = 'time-tracking-add-edit-form.errors.pause_end_after_end',
	ENTRY_TIME_MORE_GREATER_24_HOURS = 'time-tracking-add-edit-form.errors.entry_length_greater_24_hours',
	ENTRY_START_AFTER_END = 'time-tracking-add-edit-form.errors.entry_starts_after_end',
}

export interface TrackedTimeAddEditForm {
	startDate: Date;
	startTime: Date;
	endDate: Date;
	endTime: Date;
	pause: { start: Date; duration: number };
	workTypeId: string;
	assigneeIds: string[];
	projectId: string;
	comment: string;
}
