<div [class.work-type-list-panel-visible]="workTypeListPanelVisible || changeLogVisible" class="tracked-time-add-edit-form-container">
	<section class="tracked-time-add-edit-form-section px-3 h-100 flex-scroll">
		<div class="flex-scroll__container w-100">
			<div *ngIf="isComponentReady" class="d-flex align-items-center mb-3">
				<div class="d-flex align-items-center">
					<button (click)="onCancel()" class="mr-3" mat-icon-button>
						<mat-icon class="close-btn-icon" data-cy="close-time-tracking">clear</mat-icon>
					</button>

					<div class="m-0 d-inline-flex mat-headline font-weight-bold">
						<span *ngIf="isAddMode" data-cy="add-time-tracking-label">{{ 'time-tracking-add-edit-form.heading-create' | translate }}</span>
						<span *ngIf="isEditMode" data-cy="edit-time-tracking-label">{{ 'time-tracking-add-edit-form.heading-edit' | translate }}</span>
					</div>
				</div>

				<div class="ml-auto d-flex align-items-center">
					<button
						(click)="deleteTrackedTime(this.trackedTimeUpdated)"
						*ngIf="isEditMode && this.trackedTimeForm.enabled"
						class="mr-3"
						mat-icon-button
					>
						<mat-icon data-cy="delete-time-entry">delete_outline</mat-icon>
					</button>

					<button
						*ngIf="trackedTimeForm.enabled"
						(click)="submitForm(this.trackedTimeUpdated); isFormValidated = true"
						[disabled]="!isFormValid"
						cdkfocusinitial
						color="accent"
						mat-flat-button
					>
						<span *ngIf="isAddMode" data-cy="save-new-time-entry">{{ 'time-tracking-add-edit-form.submit-cta-create' | translate }}</span>
						<span *ngIf="isEditMode" data-cy="save-edited-time-entry">{{ 'time-tracking-add-edit-form.submit-cta-edit' | translate }}</span>
					</button>
				</div>
			</div>

			<div class="flex-scroll__container__scrollable-content">
				<div class="d-flex mb-3 flex-wrap">
					<span>{{ 'time-tracking-add-edit-form.duration' | translate }}</span>
					<span *ngIf="isComponentReady" class="font-weight-bold ml-auto">
						{{ startEndDurationFormatted.hr }} {{ 'time-tracking-add-edit-form.duration-hrs' | translate }}
						{{ startEndDurationFormatted.min }} {{ 'time-tracking-add-edit-form.mins' | translate }}
					</span>
				</div>

				<form [formGroup]="trackedTimeForm">
					<div class="d-flex flex-wrap">
						<mat-form-field appearance="outline" class="form-field-outline mr-3 flex-grow-1" color="accent" floatLabel="always">
							<mat-label>{{ 'time-tracking-add-edit-form.start-date' | translate }}</mat-label>
							<input
								(click)="startDatePickerRef.open()"
								(dateChange)="onChangeStartDate($event)"
								[matDatepicker]="startDatePickerRef"
								formControlName="startDate"
								matInput
								readonly
								data-cy="time-tracking-start-date"
							/>
							<mat-datepicker-toggle [for]="startDatePickerRef" matSuffix></mat-datepicker-toggle>
							<mat-datepicker #startDatePickerRef color="accent"></mat-datepicker>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-field-outline time-picker" floatLabel="always">
							<mat-label>{{ 'time-tracking-add-edit-form.start-time' | translate }}</mat-label>
							<app-time-picker [max]="endDateTime" formControlName="startTime" data-cy="start-time-picker"></app-time-picker>
						</mat-form-field>
					</div>

					<div class="d-flex flex-wrap">
						<mat-form-field appearance="outline" class="form-field-outline mr-3 flex-grow-1" color="accent" floatLabel="always">
							<mat-label>{{ 'time-tracking-add-edit-form.end-date' | translate }}</mat-label>
							<input
								(click)="endDatePickerRef.open()"
								(dateChange)="onChangeEndDate($event)"
								[matDatepicker]="endDatePickerRef"
								formControlName="endDate"
								matInput
								readonly
								data-cy="time-tracking-end-date"
							/>
							<mat-datepicker-toggle [for]="endDatePickerRef" matSuffix></mat-datepicker-toggle>
							<mat-datepicker #endDatePickerRef color="accent"></mat-datepicker>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-field-outline time-picker" floatLabel="always">
							<mat-label>{{ 'time-tracking-add-edit-form.end-time' | translate }}</mat-label>
							<app-time-picker formControlName="endTime" data-cy="end-time-picker"></app-time-picker>
						</mat-form-field>
					</div>

					<app-tracked-time-pause-button [required]="true" class="d-block" formControlName="pause"></app-tracked-time-pause-button>

					<p *ngIf="isDisabledByFourtyEightHoursRule; else errors" class="text-warn py-2">
						{{ 'project-task-list.edit-denied-time' | translate }}
					</p>
					<ng-template #errors>
						<p *ngIf="trackedTimeForm.errors?.error" class="text-warn py-2">{{ trackedTimeForm.errors?.error | translate }}</p>
					</ng-template>

					<app-tracked-time-work-type-button
						(openWorkTypeList)="openWorkTypeListPanel()"
						[required]="true"
						class="mb-3 d-block"
						[fallbackWorkTypeName]="workType?.name"
						[isFormValidated]="isFormValidated"
						formControlName="workTypeId"
					>
					</app-tracked-time-work-type-button>

					<app-time-tracking-assignee-input
						[disabled]="isEditMode || (isCurrentUserNotAdmin$ | async)"
						[showAssigneePaywall]="showAssigneePaywall"
						[fallBackAssignees]="fallBackAssignees$ | async"
						[projectMembers]="projectMembers$ | async"
						[required]="true"
						class="d-block"
						formControlName="assigneeIds"
					>
					</app-time-tracking-assignee-input>

					<div [class.d-none]="!showProjectRedirection" class="tracked-time-select-project-button-container mb-3 disabled">
						<div class="align-items-center d-flex flex-shrink-0 pr-3">
							<img alt="" class="icon-image d-inline-flex mr-3 img-fluid" src="assets/images/icons/time-tracking/project.svg" />
							<span>{{ 'time-tracking-add-edit-form.project' | translate }}</span>
						</div>
						<div *ngVar="project$ | async as project" class="align-items-center min-width-0 d-flex ml-auto pointer">
							<span class="flex-shrink-1 text-truncate min-width-0 font-weight-bold">{{ project?.name }}</span>
							<button class="tracked-time-select-project-button" mat-icon-button>
								<mat-icon [size]="25" color="primary">chevron_right</mat-icon>
							</button>
						</div>
					</div>

					<div [class]="showProjectRedirection && (projectRoute$ | async) ? 'd-flex' : 'd-none'" class="mb-3 justify-content-end">
						<a
							[routerLink]="projectRoute$ | async"
							class="d-inline-flex font-weight-bold nmt-2 text-primary"
							href=""
							rel="noopener"
							target="_blank"
						>
							<mat-icon [size]="17" class="mr-1">open_in_new</mat-icon>
							{{ 'time-tracking-add-edit-form.open-project-new-tab' | translate }}
						</a>
					</div>

					<mat-form-field appearance="outline" class="form-field-outline w-100" color="accent" floatLabel="always">
						<mat-label>{{ 'time-tracking-add-edit-form.notes' | translate }}</mat-label>
						<textarea [rows]="5" formControlName="comment" matInput></textarea>
					</mat-form-field>
				</form>
				<ng-container *ngIf="isEditMode">
					<app-tracked-time-last-changes [trackedTime]="trackedTime" (openChangeLog)="openChangeLog()"></app-tracked-time-last-changes>
				</ng-container>
			</div>
		</div>
	</section>

	<section class="work-type-list-panel-container px-3 h-100">
		<app-work-type-list-panel
			*ngIf="workTypeListPanelVisible"
			[fallbackWorkType]="workType"
			(closeWorkTypeListPanel)="closeWorkTypeListPanel()"
		></app-work-type-list-panel>
		<app-tracked-time-all-changes *ngIf="changeLogVisible" (closePanel)="closeChangeLog()"></app-tracked-time-all-changes>
	</section>
</div>
