import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { filter, first, map, skipWhile, switchMap, take } from 'rxjs/operators';
import { first as loFirst, intersection } from 'lodash';
import { environment } from '@env/environment';
import { AlertService } from '@injectables/services/alert/alert.service';
import { COLLECTION_PROJECTS } from '@shared/constants/firebase';
import {
	isOwnerOfCompany,
	selectCompanyId,
	selectUserEmail,
	selectUserRole,
} from '@store/selectors/app.selectors';
import { MemberRole, Project, ProjectType, SetResourceStatusRequest } from 'domain-entities';
import { Store } from '@ngrx/store';

import { AppState } from '@store/state/app.state';
import {
	selectActiveProjectsLoaded,
	selectAllActiveProjects,
	selectAllActiveProjectsEntities,
	selectAllArchivedProjects,
	selectAllProjects,
	selectArchivedProjectsLoaded,
	selectDeletedProject,
	selectProject,
	selectProjectFromArchived,
} from '@store/selectors/projects.selectors';
import { ProfileSettingService } from '@injectables/services/profile-setting.service';
import {
	ProjectsProjectArchivedEventBuilder,
	ProjectsProjectCreatedEventBuilder,
	ProjectsProjectDeletedEventBuilder,
	ProjectsProjectUnarchivedEventBuilder,
} from '@generated/events/ProjectsEvents.generated';
import { selectCompanyProjects } from '@store/selectors/company-projects.selectors';
import { TrackingService } from '@injectables/services/tracking.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ConfirmDialogConfig, ConfirmDialogService } from '@craftnote/material-theme';
import {
	ConfirmationDialogTranslatedService,
	InterpolationValuesModel,
} from '@injectables/services/confirmation-dialog-translated.service';
import { ProjectUnreadsService } from '@injectables/services/project-unreads.service';
import { FirestoreConnector, RequiredKey } from '@craftnote/shared-injectables';
import { GeoLocationService } from '@injectables/services/geo-location/geo-location.service';
import { ProjectConnector } from '@shared/firebase/connectors/firestore/collections/project/project.connector';
import { DeletedProjectConnector } from '@shared/firebase/connectors/firestore/collections/project/deleted-project.connector';
import {
	doesProjectChangeRequireLastEditedUpdate,
	getAddressOfProject,
	getMembersOfProject,
	getProjectTypeAsString,
} from '@shared/functions/project/project.functions';
import { Contact } from '@shared/models/contact.model';
import { EntityChanges, getMemberFullName } from '@craftnote/shared-utils';
import { LocalStorageService } from '@injectables/services/local-storage.service';
import { ProjectSortAndSearchHelper } from '@injectables/services/project-sort-and-search-helper.service';
import {
	selectIsCurrentUserHasProjectInvitationsRemaining,
	selectIsMemberHasProjectInvitationsRemaining,
} from '@store/selectors/profile-limits.selectors';
import { NoInvitationsRemainingService } from '@injectables/services/no-invitations-remaining.service';
import { TranslateService } from '@ngx-translate/core';
import { LinkService } from '@injectables/services/link/link.service';
import { Router } from '@angular/router';
import { getUnixTime } from 'date-fns';

export const EXAMPLE_PROJECT_NAMES = ['Beispiel-Projekt', 'Unternehmens-Chat'];

const PROJECT_REQUIRED_FIELDS: RequiredKey<Project>[] = ['id', 'name', 'company', 'members'];

@Injectable({
	providedIn: 'root',
})
export class ProjectService {
	private projects = new BehaviorSubject<Project[]>([]);
	private archivedProjects = new BehaviorSubject<Project[]>([]);

	constructor(
		private readonly alertService: AlertService,
		private readonly http: HttpClient,
		private readonly locationService: GeoLocationService,
		private readonly store: Store<AppState>,
		private readonly firestoreConnector: FirestoreConnector,
		private readonly projectConnector: ProjectConnector,
		private readonly deletedProjectsConnector: DeletedProjectConnector,
		private readonly profileSettingsService: ProfileSettingService,
		private readonly localStorageService: LocalStorageService,
		private readonly projectSortAndSearchHelper: ProjectSortAndSearchHelper,
		private readonly trackingService: TrackingService,
		private readonly angularFireFunctions: AngularFireFunctions,
		private readonly confirmationDialogTranslatedService: ConfirmationDialogTranslatedService,
		private readonly projectUnreadsService: ProjectUnreadsService,
		private readonly translateService: TranslateService,
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly linkService: LinkService,
		private readonly router: Router,
		private readonly noInvitationsRemainingService: NoInvitationsRemainingService,
	) {
		this.loadProjects();
	}

	async createProject(project: Project, message?: string): Promise<void> {
		const address = getAddressOfProject(project);
		const extendedProject = { ...project };
		if (address) {
			const location = await this.locationService.getLocationFromAddress(address);
			if (location && location.length > 0) {
				extendedProject.latitude = +location[0].lat;
				extendedProject.longitude = +location[0].lon;
			}
		}

		await this.projectConnector.createProject(extendedProject);
		await this.trackingService.trackEvent(
			new ProjectsProjectCreatedEventBuilder({
				projectId: extendedProject.id,
				type: extendedProject.projectType === ProjectType.FOLDER ? 'folder' : 'project',
			}),
		);
		if (message) {
			this.alertService.showAlert(message);
		}
	}

	async moveProject(project: Project, toFolder?: Project, withMessage?: string): Promise<void> {
		const actions: (() => Promise<void>)[] = [];

		const newParentId = toFolder ? toFolder.id : undefined;

		// Update parent reference of project
		actions.push(() => this.updateProjectPartial(project.id, { parentProject: newParentId }));

		await Promise.all(actions.map((action) => action()));

		if (withMessage) {
			this.alertService.showAlert(withMessage);
		}
	}

	// FIXME: Remove withMessage param, Single-responsibility principle
	public async updateProjectTransactional(
		projectId: string,
		updateFunction: (oldProject: Project) => Partial<Project> | undefined,
		withMessage?: string,
	): Promise<void> {
		await this.projectConnector.updateProjectTransactional(projectId, updateFunction);
		if (withMessage) {
			this.alertService.showAlert(withMessage);
		}
	}

	public async updateProjectPartial(
		projectId: string,
		updateObject: Partial<Project>,
		withMessage?: string,
	): Promise<void> {
		updateObject = doesProjectChangeRequireLastEditedUpdate(updateObject)
			? { ...updateObject, lastEditedDate: getUnixTime(Date.now()) }
			: updateObject;
		await this.firestoreConnector.updateDocumentPartial<Project>(
			COLLECTION_PROJECTS,
			projectId,
			updateObject,
			PROJECT_REQUIRED_FIELDS,
		);
		if (withMessage) {
			this.alertService.showAlert(withMessage);
		}
	}

	public getProjects(archived: boolean): Observable<Project[]> {
		return archived ? this.archivedProjects : this.projects;
	}

	getExternalMembers(token: string, search: string): Observable<Contact[]> {
		const params = {
			searchtext: search,
		};
		if (params.searchtext.includes('+')) {
			params.searchtext = params.searchtext.replace('+', encodeURIComponent('+'));
		}
		// json.parse(json.stringify) in order to remove undefined fields.
		const options = { headers: this.createHeaders(token), params: params };

		return this.http.get(environment.baseUrl + 'searchprofile', options).pipe(
			map((json: any) => {
				const contacts: Contact[] = [];
				for (const data of json.result) {
					const contact = new Contact().deserialize(data);
					contacts.push(contact);
				}
				return contacts;
			}),
		);
	}

	createHeaders(token: string): HttpHeaders {
		return new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', 'Bearer ' + token);
	}

	getProjectsByParent(
		parentId: string,
		isArchivedOrActive: 'active' | 'archived' | null = null,
	): Observable<Project[]> {
		if (isArchivedOrActive === 'active') {
			return this.projects.pipe(
				map((projects) => projects.filter((project) => project.parentProject === parentId)),
			);
		}

		if (isArchivedOrActive === 'archived') {
			return this.archivedProjects.pipe(
				map((projects) => projects.filter((project) => project.parentProject === parentId)),
			);
		}

		return combineLatest([this.projects, this.archivedProjects]).pipe(
			map((value: [Project[], Project[]]) => {
				return [...value[0], ...value[1]].filter((project) => project.parentProject === parentId);
			}),
		);
	}

	async deleteProject(project: Project): Promise<boolean> {
		const result = await this.getDeletionApproval(project);
		if (result === null) {
			this.alertService.showAlert(`${getProjectTypeAsString(project)}.deleteFailure`);
			return false;
		}

		if (!result) {
			return false;
		}

		this.alertService.showAlert(`${getProjectTypeAsString(project)}.deleteSuccess`);

		await this.projectUnreadsService.removeProjectUnread(project);

		await this.trackingService.trackEvent(
			new ProjectsProjectDeletedEventBuilder({
				projectId: project.id,
				type: project.projectType === ProjectType.FOLDER ? 'folder' : 'project',
			}),
		);

		return true;
	}

	/**
	 * @deprecated Use selectProject with prop projectId selector instead
	 */
	getProject(projectId?: string): Observable<Project> {
		return combineLatest([this.projects, this.archivedProjects]).pipe(
			map((value: [Project[], Project[]]) => [...value[0], ...value[1]]),
			filter((projects) => projects.length > 0),
			map((projects) => {
				return projects.find((project) => project.id === projectId);
			}),
		);
	}

	isExternalMemberOfProject$(project: Project): Observable<boolean> {
		return this.store.select(selectUserEmail).pipe(
			skipWhile((email) => !email),
			take(1),
			map((userEmail) => {
				const projectMember = getMembersOfProject(project).find(
					(member) => member.email === userEmail,
				);
				const externalRoles = [MemberRole.EXTERNALSUPERVISOR, MemberRole.EXTERNAL];
				return projectMember && externalRoles.includes(projectMember.role);
			}),
		);
	}

	loadProjects(): void {
		this.store.select(selectAllActiveProjects).subscribe((projects) => {
			this.projects.next(projects);
		});

		this.store.select(selectAllArchivedProjects).subscribe((projects) => {
			this.archivedProjects.next(projects);
			// Changes in archived projects might have implications on which projects we display
			// as active. Therefore we need to retrigger the project subject which will cause
			// a new check on which projects are implicitly archived
			this.projects.next(this.projects.value);
		});
	}

	initWatchActiveProjects(userId: string): Observable<EntityChanges<Project>> {
		return this.projectConnector.watchActiveProjects(userId);
	}

	initWatchArchivedProjects(userId: string): Observable<EntityChanges<Project>> {
		return this.projectConnector.watchArchivedProjects(userId);
	}

	initWatchDeletedProjects(companyId: string): Observable<EntityChanges<Project>> {
		return this.deletedProjectsConnector.watchDeletedProjects(companyId);
	}

	async restoreProjectWithProjectInvitationsRemainingCheck(projectId: string): Promise<void> {
		const project = await firstValueFrom(this.store.select(selectDeletedProject, { projectId }));

		if (
			EXAMPLE_PROJECT_NAMES.includes(project.name) &&
			project.projectType === ProjectType.PROJECT
		) {
			this.restoreProject(projectId);
			return;
		}

		// Checking if current user has project invitations remaining
		const isCurrentUserHasProjectInvitationsRemaining = await firstValueFrom(
			this.store.select(selectIsCurrentUserHasProjectInvitationsRemaining()),
		);

		if (!isCurrentUserHasProjectInvitationsRemaining) {
			this.noInvitationsRemainingService.openNoProjectInvitationsRemainingPaywallDialog(
				'project-restore',
				{
					title: this.translateService.instant('create-project.no-project-remaining.title'),
					message: this.translateService.instant('create-project.no-project-remaining.message'),
					messageList: [
						this.translateService.instant('create-project.no-project-remaining.message-1'),
						this.translateService.instant('create-project.no-project-remaining.message-2'),
						this.translateService.instant('create-project.no-project-remaining.message-3'),
					],
				},
			);
			return;
		}

		// Restore project if current user has project invitations remaining and for members skipping the ones with no invitations remaining
		this.restoreProject(projectId);

		const projectsMembersWithNoInvitationsRemaining = (
			await Promise.all(
				Object.values(project.members).map((member) =>
					firstValueFrom(
						this.store.select(selectIsMemberHasProjectInvitationsRemaining(member.id)).pipe(
							map((isMemberHasProjectInvitationsRemaining) => ({
								member,
								isMemberHasProjectInvitationsRemaining,
							})),
						),
					),
				),
			)
		).filter(
			({ isMemberHasProjectInvitationsRemaining }) =>
				isMemberHasProjectInvitationsRemaining === false,
		);
		// We will skip the members with no invitations remaining and restore the project for the rest of the members
		if (projectsMembersWithNoInvitationsRemaining.length > 0) {
			const message =
				this.translateService.instant(
					'restorable-projects.restore-project-restriction-dialog.message',
				) +
				`<br>${projectsMembersWithNoInvitationsRemaining.map(
					({ member }) => `<br>${getMemberFullName(member)}`,
				)}`;
			const openUpgradePage = await firstValueFrom(
				this.confirmDialogService
					.open({
						title: this.translateService.instant(
							'restorable-projects.restore-project-restriction-dialog.title',
						),
						message,
						primaryButtonColor: 'accent',
						primaryButtonText: this.translateService.instant(
							'restorable-projects.restore-project-restriction-dialog.upgrade-button',
						),
						primaryButtonValue: true,
						showSecondaryButton: false,
					})
					.afterClosed(),
			);
			if (openUpgradePage) {
				const isOwner = await firstValueFrom(this.store.select(isOwnerOfCompany));
				if (isOwner) {
					await this.router.navigate(['settings/subscription/products']);
				} else {
					this.linkService.openLinkInNewTab(this.linkService.pricePage);
				}
			}
		}
	}

	private async restoreProject(projectId: string): Promise<void> {
		const project = await this.store
			.select(selectDeletedProject, { projectId })
			.pipe(take(1))
			.toPromise();
		await this.setProjectState(project.id, 'active');

		// Make sure the project has vanished from the store before returning
		await this.store
			.select(selectDeletedProject, { projectId: project.id })
			.pipe(
				filter((proj) => !proj),
				take(1),
			)
			.toPromise();
	}

	watchCompanyProjects(companyId: string): Observable<EntityChanges<Project>> {
		return this.projectConnector.watchCompanyProjects(companyId);
	}

	watchProjects(projectIds: string[]): Observable<Project[]> {
		return combineLatest([
			this.store.select(selectCompanyId),
			this.getAccessibleProjectsIds(),
		]).pipe(
			switchMap(([companyId, accessibleProjectIds]) =>
				this.projectConnector.watchProjects(
					companyId,
					intersection(accessibleProjectIds, projectIds),
				),
			),
		);
	}

	watchProject(projectId: string): Observable<Project> {
		return this.store.select(selectCompanyId).pipe(
			switchMap((companyId) => this.projectConnector.watchProjects(companyId, [projectId])),
			map((projects) => loFirst(projects)),
		);
	}

	private getAccessibleProjectsIds(): Observable<string[]> {
		return this.store.select(selectUserRole).pipe(
			switchMap((role) =>
				this.store.select(role === MemberRole.OWNER ? selectCompanyProjects : selectAllProjects),
			),
			map((projects) => projects.map((project) => project.id)),
		);
	}

	async getIdOfStandardProject(projectName?: string, isArchive: boolean = false): Promise<string> {
		const lastOpenedProjectId = await this.localStorageService.get('project');

		let projects: Project[];

		if (isArchive) {
			await this.store
				.select(selectArchivedProjectsLoaded)
				.pipe(filter(Boolean), take(1))
				.toPromise();
			projects = await this.store.select(selectAllArchivedProjects).pipe(take(1)).toPromise();
		} else {
			await this.store
				.select(selectActiveProjectsLoaded)
				.pipe(filter(Boolean), take(1))
				.toPromise();
			projects = await this.store.select(selectAllActiveProjects).pipe(take(1)).toPromise();
		}

		// When user clicks on the projects or craftnote logo, user should redirect to the root level even the lastOpenedProject is in folder
		// and the projectName is only passed in the user registration flow
		const viewableProject = projects.find(
			(project) =>
				(projectName && project.name.toLowerCase() === projectName.toLowerCase()) ||
				(!project.parentProject && project.id === lastOpenedProjectId),
		);

		if (viewableProject) {
			return viewableProject.id;
		}

		const { sortOptions } = await this.profileSettingsService
			.getProfileSettings()
			.pipe(first())
			.toPromise();
		const sortedProjects = this.projectSortAndSearchHelper.sort(
			projects,
			sortOptions.sortBy,
			sortOptions.orderBy,
		);

		// returning the top most project's id based the current sorting options
		return sortedProjects.find(
			(project) => project.projectType === ProjectType.PROJECT && !project.parentProject,
		)?.id;
	}

	async archiveProject(
		project: Project,
		archivationOptions: { requireApproval: boolean } = { requireApproval: true },
	): Promise<boolean> {
		const success = await this.getArchivationApproval(project, archivationOptions.requireApproval);
		if (success === false) {
			return false;
		}

		if (archivationOptions.requireApproval) {
			const messageResultPart = success ? 'Success' : 'Failure';
			const message = `${getProjectTypeAsString(project)}.archive${messageResultPart}`;
			this.alertService.showAlert(message);
		}

		if (!success) {
			return false;
		}

		await this.trackingService.trackEvent(
			new ProjectsProjectArchivedEventBuilder({
				projectId: project.id,
				type: project.projectType === ProjectType.FOLDER ? 'folder' : 'project',
			}),
		);
		return true;
	}

	private async getArchivationApproval(project: Project, requireApproval: boolean): Promise<any> {
		const archiveProject = async () => {
			await this.setProjectState(project.id, 'archived');

			// Make sure the project has vanished from the store before returning
			await this.store
				.select(selectAllActiveProjectsEntities)
				.pipe(
					filter((entities) => !entities[project.id]),
					take(1),
				)
				.toPromise();
		};

		if (!requireApproval) {
			await archiveProject();
			return true;
		}
		return this.showStatusChangeDialog(project, 'archive', async (actionValue) => {
			if (!actionValue) {
				return;
			}
			await archiveProject();
		});
	}

	private async getUnarchivationApproval(project: Project): Promise<any> {
		let configOverride: Partial<ConfirmDialogConfig> = {};

		/**
		 * If the user chooses the secondary option (archive project's parent and all subprojects)
		 * Then archive the parent and wait for the parent to vanish
		 */
		const callbackOnUnarchiveDecision = async (actionValue: string | boolean) => {
			if (actionValue === false) {
				return;
			}
			const relevantProjectId =
				actionValue === 'unarchive-folder' ? project.parentProject : project.id;
			await this.setProjectState(relevantProjectId, 'active');

			// Make sure the project has vanished from the store before returning
			await this.store
				.select(selectProjectFromArchived, { projectId: relevantProjectId })
				.pipe(
					filter((foundProject) => !foundProject),
					take(1),
				)
				.toPromise();
		};

		if (await this.isProjectWithArchivedParent(project)) {
			configOverride = {
				contentHint: 'project.unarchive_dialog.hint',
				secondaryButtonText: 'project.unarchive_dialog.approve_for_all_secondary',
				tertiaryButtonText: 'project.unarchive_dialog.cancel',
				showTertiaryButton: true,
				secondaryButtonValue: 'unarchive-folder',
				tertiaryButtonValue: false,
			};
		}

		return this.showStatusChangeDialog(
			project,
			'unarchive',
			callbackOnUnarchiveDecision,
			configOverride,
		);
	}

	private async isProjectWithArchivedParent(project: Project): Promise<boolean> {
		if (project.projectType !== ProjectType.PROJECT) {
			return false;
		}

		return !!(await this.store
			.select(selectProjectFromArchived, {
				projectId: project.parentProject,
			})
			.pipe(take(1))
			.toPromise());
	}

	private async getDeletionApproval(project: Project): Promise<any> {
		return this.showStatusChangeDialog(project, 'delete', async (actionValue) => {
			if (!actionValue) {
				return;
			}

			await this.setProjectState(project.id, 'deleted');

			// Make sure the project has vanished from the store before returning
			await this.store
				.select(selectProject, { projectId: project.id })
				.pipe(
					filter((proj) => !proj),
					take(1),
				)
				.toPromise();
		});
	}

	private async showStatusChangeDialog(
		project: Project,
		action: 'archive' | 'unarchive' | 'delete' | 'restore',
		actionCallBack: (dialogValue: any) => Promise<void>,
		configOverrides: Partial<ConfirmDialogConfig> = {},
	): Promise<any> {
		const typeSnippet = project.projectType === ProjectType.PROJECT ? 'project' : 'folder';
		const interpolationValues: InterpolationValuesModel = {
			message: { name: project.name },
		};
		return (
			await this.confirmationDialogTranslatedService.open(
				{
					title: `${typeSnippet}.actions.${action}.dialog.title`,
					message: `${typeSnippet}.${action}_dialog.approval`,
					primaryButtonColor: 'accent',
					showCrossBtn: false,
					showSecondaryButton: true,
					primaryButtonText: `project.${action}_dialog.approve_for_all`,
					secondaryButtonText: `project.${action}_dialog.cancel`,
					primaryButtonValue: true,
					secondaryButtonValue: false,
					disableClose: true,
					actionPerformedBeforeClosing: actionCallBack,
					actionErrorValue: null,
					...configOverrides,
				},
				interpolationValues,
			)
		)
			.afterClosed()
			.pipe(take(1))
			.toPromise();
	}

	async unarchiveProject(project: Project): Promise<boolean | 'unarchive-folder'> {
		const result = await this.getUnarchivationApproval(project);

		if (result === false) {
			return false;
		}

		const messageResultPart = result ? 'Success' : 'Failure';
		const projectType = result === 'unarchive-folder' ? ProjectType.FOLDER : project.projectType;

		const message =
			projectType === ProjectType.PROJECT
				? `project.unarchive${messageResultPart}`
				: `folder.unarchive${messageResultPart}`;
		this.alertService.showAlert(message);

		if (!result) {
			return false;
		}

		const params = {
			projectId: project.id,
			type: projectType ? 'project' : 'folder',
		};

		await this.trackingService.trackEvent(new ProjectsProjectUnarchivedEventBuilder(params));

		return result;
	}

	private async setProjectState(
		projectId: string,
		state: 'active' | 'archived' | 'deleted',
	): Promise<boolean> {
		try {
			await this.angularFireFunctions
				.httpsCallable<SetResourceStatusRequest>('setResourceStatus')({
					resourceType: 'project',
					resourceId: projectId,
					statusName: state,
					context: { platform: 'web' },
				})
				.toPromise();
			return true;
		} catch (e) {
			return false;
		}
	}
}
