import {
	addSeconds,
	differenceInDays,
	differenceInSeconds,
	isAfter,
	isBefore,
	isValid,
	isWithinInterval,
	startOfDay,
	subMilliseconds,
} from 'date-fns';
import { UntypedFormGroup } from '@angular/forms';
import {
	TrackedTimeAddEditErrors,
	TrackedTimeAddEditForm,
} from '../../types/tracked-time-add-edit-form.types';

export function getPauseStart(trackedTimeForm: TrackedTimeAddEditForm): Date {
	if (
		!(trackedTimeForm.startTime && trackedTimeForm.endTime && trackedTimeForm.pause?.start) ||
		trackedTimeForm.startTime > trackedTimeForm.endTime
	) {
		return null;
	}
	/**
	 * The selected pause start represented as seconds since midnight
	 */
	const pauseSecondsSinceMidnight = differenceInSeconds(
		trackedTimeForm.pause.start,
		startOfDay(trackedTimeForm.pause.start),
	);

	/**
	 * There are (at maximum) two possible pause start dates:
	 * One referencing the day of the tracked time's start and one referencing the day of its end.
	 * Since we assume a maximum tracked time length of 24h there are no other possibilities.
	 */
	const pauseTimeStartCandidates = [trackedTimeForm.startTime, trackedTimeForm.endTime].map(
		(baseTime) => addSeconds(startOfDay(baseTime), pauseSecondsSinceMidnight),
	);

	/**
	 * We check all candidates whether they are within the tracked times interval and return t
	 */
	return (
		pauseTimeStartCandidates.find((pauseTimeStartCandidate) =>
			isWithinInterval(pauseTimeStartCandidate, {
				start: trackedTimeForm.startTime,
				end: trackedTimeForm.endTime,
			}),
		) ?? null
	);
}

export function pauseValidator(trackedTimeForm: UntypedFormGroup): any {
	const formValue: TrackedTimeAddEditForm = trackedTimeForm.value;

	const pause = formValue.pause;
	if (!pause.duration) {
		return;
	}

	const pauseStart = getPauseStart(formValue);
	const endTime = formValue.endTime;

	if (!isValid(pauseStart) && pause.duration) {
		return { error: TrackedTimeAddEditErrors.INVALID_PAUSE_START };
	}
	const pauseEnd = addSeconds(pauseStart, pause.duration);
	if (!isBefore(pauseEnd, endTime)) {
		return { error: TrackedTimeAddEditErrors.PAUSE_ENDS_AFTER_END };
	}
}

export function trackedTimeValidator(trackedTimeForm: UntypedFormGroup): any {
	const formValue: TrackedTimeAddEditForm = trackedTimeForm.value;
	const start = formValue.startTime;
	const end = formValue.endTime;

	if (isAfter(start, end)) {
		return { error: TrackedTimeAddEditErrors.ENTRY_START_AFTER_END };
	}

	if (differenceInDays(subMilliseconds(end, 1), start) > 0) {
		return { error: TrackedTimeAddEditErrors.ENTRY_TIME_MORE_GREATER_24_HOURS };
	}
}
